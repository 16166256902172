<template>
  <ui-modal v-model="isVisible">
    <div v-if="!isResponse" class="feedback-modal">
      <div class="feedback-modal-label">
        {{ $t('feedback') }}
        <div class="x" @click="isVisible = false" />
      </div>
      <ui-input
        v-model="formData.form.name"
        :placeholder="$t('name')"
        class="input"
      />
      <ui-input
        v-model="formData.form.email"
        :placeholder="$t('emailFeedBack2')"
        class="input"
        @blur="v$.form.email.$touch"
      />
      <textarea
        v-model="formData.form.message"
        class="textarea"
        :placeholder="$t('message')"
        maxlength="210"
      />
      <label class="textarea-count"
        >{{ formData.form.message.length }} / 210</label
      >
      <div v-if="v$.form.email.$error || error" class="error-box">
        <ErrorIcon style="width: 20px; margin-right: 10px" />
        <span>
          {{ error ? error : $t('errorFeedBack') }}
        </span>
      </div>
      <div class="modalBtn">
        <div class="modalBtn-back" @click="isVisible = false">
          {{ $t('back') }}
        </div>
        <ui-button
          color="primary"
          :label="$t('send')"
          :size="ButtonSizeType.small"
          :disabled="isDisabled"
          @click="submit"
        />
      </div>
    </div>
    <div v-else class="response-modal">
      <div class="ok">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="38"
          height="39"
          viewBox="0 0 38 39"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M30.875 19.5C30.875 26.0584 25.5584 31.375 19 31.375C12.4416 31.375 7.125 26.0584 7.125 19.5C7.125 12.9416 12.4416 7.625 19 7.625C25.5584 7.625 30.875 12.9416 30.875 19.5ZM33.25 19.5C33.25 27.3701 26.8701 33.75 19 33.75C11.1299 33.75 4.75 27.3701 4.75 19.5C4.75 11.6299 11.1299 5.25 19 5.25C26.8701 5.25 33.25 11.6299 33.25 19.5ZM26.173 16.3814C26.6368 15.9176 26.6368 15.1657 26.173 14.702C25.7093 14.2382 24.9574 14.2382 24.4936 14.702L17.4167 21.779L14.298 18.6603C13.8343 18.1966 13.0824 18.1966 12.6186 18.6603C12.1549 19.1241 12.1549 19.8759 12.6186 20.3397L16.577 24.298C17.0407 24.7618 17.7926 24.7618 18.2564 24.298L26.173 16.3814Z"
            fill="#3E9EEA"
          />
        </svg>
      </div>
      <div class="response-modal-text">
        {{ $t('feedBackResp') }}
      </div>
      <div class="response-modal-close" @click="isVisible = false">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="21"
          viewBox="0 0 20 21"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M15.4427 5.942C15.6868 5.69791 15.6868 5.30216 15.4427 5.05807C15.1986 4.81398 14.8029 4.81398 14.5588 5.05807L10.0005 9.61634L5.44225 5.05807C5.19816 4.81398 4.80241 4.81398 4.55832 5.05807C4.31423 5.30216 4.31423 5.69791 4.55832 5.942L9.11658 10.5003L4.55831 15.0586C4.31422 15.3026 4.31422 15.6984 4.55831 15.9425C4.8024 16.1866 5.19815 16.1866 5.44224 15.9425L10.0005 11.3842L14.5588 15.9425C14.8029 16.1866 15.1986 16.1866 15.4427 15.9425C15.6868 15.6984 15.6868 15.3027 15.4427 15.0586L10.8844 10.5003L15.4427 5.942Z"
            fill="#B2C0CD"
          />
        </svg>
      </div>
    </div>
  </ui-modal>
</template>

<script lang="ts" setup>
import {useVuelidate} from '@vuelidate/core';
import {useValidators} from '~/hooks/useValidators';
import {ButtonSizeType} from '~/interfaces/ui/button';
import ErrorIcon from '~/assets/svg/redexclamationmark.svg';
import {api} from '~/plugins/axios';
import Logger from '~/helpers/logger';

const logger = Logger.getInstance();
const props = withDefaults(
  defineProps<{
    show: boolean;
  }>(),
  {
    show: false,
  },
);
const emits = defineEmits(['update:show']);
const isVisible = computed({
  get() {
    return props.show;
  },
  set(value) {
    emits('update:show', value);
    isResponse.value = false;
    error.value = '';
  },
});
// const { t } = useI18n()
const {required, email} = useValidators();
const error = ref('');
const isResponse = ref(false);
const formData = reactive({
  form: {
    name: '',
    email: '',
    message: '',
  },
});
const rules = {
  form: {
    name: {required},
    email: {required, email},
    message: {required},
  },
};
const v$ = useVuelidate(rules, formData);
const isDisabled = computed(() => {
  return !(
    formData.form.name &&
    !v$.value.form.email.$error &&
    formData.form.message &&
    formData.form.email
  );
});

const submit = async () => {
  try {
    const params = {
      name: formData.form.name,
      email: formData.form.email,
      message: formData.form.message,
    };
    // const response = await api.get('https://script.google.com/macros/s/AKfycbwRTwgtOtFqMRKZ4tRhrYBOV6OaNqrD9pLtrp_bWhcO7J6Mv6C0r456qg2bt0E3InncJg/exec', { params })
    const response = await api.post(
      'https://dreamflat.design/proxy/profile/send_mail',
      {
        destination: 'support@dreamflat.design',
        subject: `${params.name} - ${params.email}`,
        body: params.message,
      },
    );
    if (response.data.error) {
      error.value = 'Server error';
      logger.error(`[API] try to post /proxy/profile/send_mail: ${response.data.error}`)
    } else {
      logger.info(`[API] success post /proxy/profile/send_mail`)
      isResponse.value = true;
      formData.form.email = '';
      formData.form.name = '';
      formData.form.message = '';
      v$.value.$reset();
    }
  } catch (err) {
    error.value = 'Server error';
    logger.error(`[API] try to post /proxy/profile/send_mail`)
  }
};
</script>

<style lang="scss" scoped>
.feedback-modal {
  width: 448px;
  min-height: 400px;
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 12px;
  border: 1px solid #64686e;
  background: var(--var-white-100);
  .input {
    width: 100%;
  }
  &-label {
    width: 100%;
    position: relative;
    color: #3d424a;
    font-family: Segoe UI;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.36px;
    .x {
      position: absolute;
      right: -16px;
      top: -16px;
      background-image: url(assets/svg/X.svg);
      background-size: 20px;
      background-position: center;
      background-repeat: no-repeat;
      height: 20px;
      width: 20px;
      cursor: pointer;
    }
  }
  .textarea {
    width: 100%;
    height: 116px;
    padding: 8px 16px;
    border-radius: 8px;
    border: none;
    outline: none;
    background: #f4f6fd;
    resize: none;
    color: #3d424a;
    font-family: Segoe UI;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.28px;
    &::placeholder {
      color: #839aaf;
      font-family: Segoe UI;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.28px;
    }
  }
  .textarea-count {
    margin-left: auto;
    color: #839aaf;
    font-family: Segoe UI;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.26px;
  }
  .error-box {
    background: #fbebe7;
    padding: 12px;
    border: 1px solid #e99b88;
    border-radius: 8px;
    font-weight: 600;
    font-size: 14px;
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
  }
  .modalBtn {
    margin-top: auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
    &-back {
      width: 89px;
      height: 32px;
      padding: 4px 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      border: 1px solid #e1e5f0;
      color: #64686e;
      text-align: center;
      font-family: Segoe UI;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.28px;
      cursor: pointer;
    }
  }
  @media (max-width: $md) {
    width: 100%;
  }
}
.response-modal {
  position: relative;
  width: 406px;
  height: 200px;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex-shrink: 0;
  border-radius: 12px;
  border: 1px solid var(--white-theme-black-200, #64686e);
  background: var(--white-theme-white-100, var(--var-white-100));
  .ok {
    position: absolute;
    left: 178px;
    top: 34px;
    width: 38px;
    height: 38px;
  }
  &-text {
    width: 356px;
    height: 48px;
    position: absolute;
    left: 24px;
    top: 88px;
    color: var(--white-theme-black-100, #3d424a);
    text-align: center;
    font-family: Segoe UI;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.36px;
  }
  &-close {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
  }
}
</style>
